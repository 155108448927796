'use client';

import { useEffect, createRef, useState, KeyboardEventHandler, FormEventHandler, ReactNode, Fragment } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {
    HiSearch,
    HiMenu,
    HiOutlineCalendar,
    HiChevronDown,
    HiX,
    HiPhone,
    HiOutlineArrowNarrowRight,
} from 'react-icons/hi';
import { RiUser3Fill, RiUser3Line } from 'react-icons/ri';

import { useRouter } from 'next/navigation';
import { usePathname } from 'next/navigation';

import Logo from '../../public/images/logo.svg';
import CompanySvg from '../../public/images/header-company.svg';
import {
    appointmentUrl,
    blogCategoryUrl,
    companyUrl,
    contactUrl,
    myAccountUrl,
    searchUrl,
    stateUrl,
    testsCategoryUrl,
} from 'src/services/route-utils';
import useMyTestsState from 'src/hooks/useMyTestsState';
import useGlobalState from 'src/hooks/useGlobalState';
import useClickAway from 'src/hooks/useClickAway';
import UserMenu from './UserMenu';

type Props = {
    navLinks: any[];
};

export default function Header({ navLinks }: Props) {
    const { tests } = useMyTestsState();
    const { user } = useGlobalState();
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const [resetHover, setResetHover] = useState(false);
    const [accordionSelectedKey, setAccordionSelectedKey] = useState<string | null>(null);
    const [ping, setPing] = useState(false);
    const accountMenuRef = useClickAway(() => closeAccount());

    const searchInputRef = createRef<HTMLInputElement>();
    const router = useRouter();
    const pathname = usePathname();

    useEffect(() => {
        let timeout: any = null;
        if (tests?.length) {
            setPing(true);
            timeout = setTimeout(() => {
                setPing(false);
            }, 1000);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [tests]);

    useEffect(() => {
        if (searchOpen) {
            searchInputRef.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOpen]);

    useEffect(() => {
        let timeout: any = null;

        if (resetHover) {
            timeout = setTimeout(() => setResetHover(false), 10);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [resetHover]);

    useEffect(() => {
        if (searchOpen || menuOpen || accountOpen) {
            disablePageScroll();
        } else {
            enablePageScroll();
        }
    }, [searchOpen, menuOpen, accountOpen]);

    const enablePageScroll = () => {
        document.body.classList.remove('overflow-hidden', 'md:overflow-scroll');
    };

    const disablePageScroll = () => {
        document.body.classList.add('overflow-hidden', 'md:overflow-scroll');
    };

    const closeMenu = () => {
        setResetHover(true);
        setAccordionSelectedKey(null);
        setMenuOpen(false);
    };

    const openMenu = () => {
        closeAllMenus();
        setMenuOpen(true);
    };

    const closeSearch = () => {
        setSearchOpen(false);
    };

    const openSearch = () => {
        closeAllMenus();
        setSearchOpen(true);
    };

    const openAccount = () => {
        closeAllMenus();
        setAccountOpen(true);
    };

    const closeAccount = () => {
        setAccountOpen(false);
    };

    const closeAllMenus = () => {
        closeMenu();
        closeAccount();
        closeSearch();
    };

    const handleSearchKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Escape') {
            closeSearch();
        }
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        const form = event.target as HTMLFormElement;
        const data = new FormData(form);
        const value = data.get('search') as string;
        if (value) {
            router.push(searchUrl(value));
        }
        closeSearch();
        form.reset();
        event.preventDefault();
    };

    const renderSubMenuSectionTitle = (title: string, leftColumn = true) => (
        <div className={`font-display mb-8 mt-10 hidden text-4xl md:block ${leftColumn ? 'xl:w-2/3' : ''}`}>
            {title}
        </div>
    );

    const MenuLink = (props) => <Link onClick={() => closeAllMenus()} {...props} />;

    const renderSubMenuLink = (item: { label: ReactNode; href: string }) => (
        <MenuLink href={item.href} className="hover:underline">
            {item.label}
        </MenuLink>
    );

    const renderMenuSublinks = (
        main: {
            subLinks: any[];
            mobileColumns: number;
            desktopColumns: number;
            compressed?: boolean;
            finalListItem?: {
                label: string;
                href: string;
            };
        },
        extra: { subLinks?: any[]; columns?: number; content?: ReactNode },
    ) => {
        let liClassName = main.mobileColumns === 2 ? 'w-1/2' : 'w-full';
        if (main.desktopColumns > main.mobileColumns) {
            liClassName += ` ${main.desktopColumns === 2 ? 'md:w-1/2' : 'md:w-1/4 xl:w-1/5'}`;
        }

        return (
            <>
                <div className="bg-primary/50 top-nav-md-height fixed left-0 -z-10 hidden h-screen w-screen hover:hidden lg:block" />
                <div className="absolute bottom-0 left-0 right-1/2 top-0 -z-10 hidden bg-white lg:block" />
                {!extra.content && (
                    <div className="bg-light-grey absolute bottom-0 left-1/2 right-0 top-0 -z-10 hidden lg:block" />
                )}
                <div className="container mx-auto px-4 pb-4 lg:flex lg:pb-0">
                    <div className="flex-1 bg-white md:pb-12">
                        {main.subLinks.map((section, index) => (
                            <Fragment key={section.text}>
                                {renderSubMenuSectionTitle(section.text)}
                                <ul className={`flex flex-wrap gap-y-4 ${main.compressed ? 'lg:w-1/2' : ''}`}>
                                    {section.items.map((item) => (
                                        <li key={item.href} className={liClassName}>
                                            {renderSubMenuLink(item)}
                                        </li>
                                    ))}
                                    {main.finalListItem && (
                                        <li className="w-full font-bold md:mt-4">
                                            {renderSubMenuLink({
                                                label: (
                                                    <>
                                                        {main.finalListItem.label}{' '}
                                                        <HiOutlineArrowNarrowRight className="inline text-lg" />
                                                    </>
                                                ),
                                                href: main.finalListItem.href,
                                            })}
                                        </li>
                                    )}
                                </ul>
                            </Fragment>
                        ))}
                    </div>
                    <div className="relative hidden lg:block lg:w-2/5 lg:pb-12 lg:pl-24 xl:w-1/3">
                        {extra.content ? (
                            <div
                                className="br-container-margin absolute -right-4 left-0 top-0 z-10 h-full overflow-hidden"
                                style={{ position: 'absolute', height: '100%' }}>
                                {extra.content}
                            </div>
                        ) : (
                            extra.subLinks?.map(
                                (section, index) =>
                                    section.items.length && (
                                        <Fragment key={section.text}>
                                            {renderSubMenuSectionTitle(section.text, false)}
                                            <ul className="flex flex-wrap gap-y-4">
                                                {section.items.map((item) => (
                                                    <li
                                                        key={item.href}
                                                        className={extra.columns === 2 ? 'w-1/2' : 'w-full'}>
                                                        {renderSubMenuLink(item)}
                                                    </li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    ),
                            )
                        )}
                    </div>
                </div>
            </>
        );
    };

    const renderSubMenu = (item: any): ReactNode => {
        if (!item.subLinks?.length) {
            return null;
        }

        let content: ReactNode = null;
        if (item.href === testsCategoryUrl(null)) {
            content = renderMenuSublinks(
                {
                    subLinks: item.subLinks,
                    mobileColumns: 1,
                    desktopColumns: 1,
                    finalListItem: { href: testsCategoryUrl(null), label: 'Toate analizele' },
                },
                { subLinks: item.extra.subLinks, columns: 2 },
            );
        } else if (item.href === stateUrl(null)) {
            content = renderMenuSublinks(
                {
                    subLinks: item.subLinks,
                    mobileColumns: 2,
                    desktopColumns: 4,
                    finalListItem: { href: stateUrl(null), label: 'Toate locațiile' },
                },
                {
                    content: (
                        <Image
                            fill
                            src="/images/header-locations.jpg"
                            className="object-cover"
                            alt="Meniu Locații"
                            sizes="33.33vw"
                        />
                    ),
                },
            );
        } else if (item.href === blogCategoryUrl(null)) {
            content = renderMenuSublinks(
                {
                    subLinks: item.subLinks,
                    mobileColumns: 1,
                    desktopColumns: 2,
                    finalListItem: { href: blogCategoryUrl(null), label: 'Toate categoriile' },
                },
                { subLinks: item.extra.subLinks, columns: 1 },
            );
        } else if (item.href === companyUrl()) {
            content = renderMenuSublinks(
                { subLinks: item.subLinks, mobileColumns: 2, desktopColumns: 2, compressed: true },
                { content: <CompanySvg className="bg-light-grey absolute h-full w-full" /> },
            );
        }

        return (
            item.subLinks?.length && (
                <div
                    className={`left-0 w-full bg-white text-base md:absolute md:z-30 md:mt-[1px] md:border-b md:drop-shadow-sm ${
                        accordionSelectedKey === item.key ? 'block md:hidden' : 'hidden'
                    } ${resetHover ? '' : 'md:group-hover:block'}`}>
                    {content}
                </div>
            )
        );
    };

    const mainSectionLiClassName = 'border-dark-green/25 border-b md:border-0';
    const mainSectionSelectionClassName = (href: string) => {
        const isActive = pathname?.indexOf(href) === 0;

        return `before:bg-primary relative before:absolute before:bottom-0 before:left-4 before:right-4 before:hidden before:h-1 md:hover:before:z-20 md:hover:before:block${
            isActive ? ' md:before:block' : ''
        }`;
    };

    return (
        <>
            {searchOpen && (
                <div className="bg-dark-green fixed z-10 h-full w-full opacity-25 md:hidden" onClick={closeSearch} />
            )}
            <header
                className={`text-primary fixed top-0 z-40 w-full select-none bg-white ${
                    menuOpen ? 'md:drop-shadow-lg' : 'drop-shadow-lg'
                }`}>
                <div className="border-dark-green border-b">
                    <nav className="border-dark-green container mx-auto flex items-center pl-4 text-xl md:py-4">
                        <Link href="/" legacyBehavior>
                            <a onClick={closeMenu}>
                                <Logo
                                    className="w-[150px] overflow-visible md:w-[200px]"
                                    alt="Bioclinica"
                                    title="Bioclinica"
                                />
                            </a>
                        </Link>
                        <div className="flex-1 text-right">
                            <form
                                onSubmit={handleSubmit}
                                className={`border-dark-green top-nav-sm-height absolute left-0 w-full border-b bg-white md:border-none ${
                                    searchOpen ? '' : 'hidden'
                                } md:static md:block md:pl-8 md:text-base`}>
                                <label
                                    htmlFor="search-input"
                                    className="container relative mx-auto block px-4 md:inline"
                                    aria-label="Introduceți termenul de căutare">
                                    <HiSearch
                                        className="pointer-events-none absolute left-6 mt-3 text-lg text-slate-400"
                                        title="Căutare"
                                    />
                                    <input
                                        id="search-input"
                                        name="search"
                                        ref={searchInputRef}
                                        onKeyUp={handleSearchKeyUp}
                                        type="search"
                                        className="md:focus:border-outline-green md:focus:ring-outline-green w-full px-4 py-2 pl-8 focus:outline-none focus:ring-0 md:w-3/4 md:rounded-full md:border md:border-slate-400 md:focus:ring-1 lg:w-2/3 xl:w-1/2 2xl:w-2/6"
                                        placeholder="Căutare"
                                        autoComplete="off"
                                    />
                                </label>
                            </form>
                        </div>

                        <a
                            href="#"
                            className="p-[14px] md:hidden"
                            aria-label="Căutare"
                            onClick={(event) => {
                                searchOpen ? closeSearch() : openSearch();
                                event.preventDefault();
                            }}>
                            <HiSearch title="Căutare" />
                        </a>
                        {user ? (
                            <div
                                ref={accountMenuRef}
                                className={`relative cursor-pointer p-[14px] py-[10px] ${
                                    pathname?.indexOf(myAccountUrl('/')) === 0 ? 'md:font-bold' : ''
                                }`}
                                onClick={accountOpen ? closeAccount : openAccount}>
                                <RiUser3Fill title={user.username} className="inline md:mr-1" />
                                <span className="hidden text-sm md:inline">
                                    {user.first_name || user.last_name
                                        ? `${user.first_name} ${user.last_name}`
                                        : user.username}{' '}
                                    {(user as any).impersonated && (
                                        <span className="bg-third mx-1 rounded-md p-1 px-2 text-xs text-white">
                                            Impersonat
                                        </span>
                                    )}
                                    <HiChevronDown
                                        aria-hidden
                                        className={`inline-block text-base transition duration-200 ease-out ${
                                            accountOpen ? 'rotate-180' : ''
                                        }`}
                                    />
                                </span>

                                {accountOpen && (
                                    <UserMenu user={user} closeAllMenus={closeAllMenus} closeAccount={closeAccount} />
                                )}
                            </div>
                        ) : (
                            <MenuLink
                                href={myAccountUrl('/')}
                                className={`p-[14px] py-[10px] ${
                                    pathname?.indexOf(myAccountUrl('/')) === 0 ? 'md:font-bold' : ''
                                }`}
                                aria-label="Contul meu">
                                <RiUser3Line title="Contul meu" className="inline md:mr-1" />
                                <span className="hidden text-sm md:inline">Contul meu</span>
                            </MenuLink>
                        )}
                        <MenuLink
                            href={appointmentUrl()}
                            className="relative p-[14px] md:hidden"
                            aria-label="Programează-te online">
                            <HiOutlineCalendar title="Programează-te online" />
                            {!!tests?.length && (
                                <div className="absolute right-2 top-2">
                                    {ping && (
                                        <div className="bg-secondary absolute -z-10 h-full w-full animate-ping rounded-full" />
                                    )}
                                    <div
                                        className="bg-secondary text-primary overflow-hidden rounded-full text-center text-sm"
                                        style={{ width: 20, height: 20 }}>
                                        {tests.length}
                                    </div>
                                </div>
                            )}
                        </MenuLink>
                        <a
                            href="#"
                            onClick={(event) => {
                                menuOpen ? closeMenu() : openMenu();
                                event.preventDefault();
                            }}
                            className="border-dark-green border-l p-[14px] px-4 md:hidden"
                            aria-label={menuOpen ? 'Închide meniul' : 'Deschide meniul'}>
                            {menuOpen ? <HiX title="Închide" /> : <HiMenu title="Meniu" />}
                        </a>
                    </nav>
                </div>
                <nav
                    className={`border-dark-green top-nav-sm-height fixed bottom-0 w-full overflow-scroll bg-white md:relative md:top-0 md:overflow-visible md:border-b ${
                        menuOpen ? 'block' : 'hidden md:block'
                    }`}>
                    <ul className="container mx-auto flex flex-col text-xl md:flex-row md:items-center md:pt-0 md:text-base md:after:bottom-0 md:after:left-0 md:after:right-0 md:after:z-10 md:after:hidden md:after:h-1 md:after:bg-white md:hover:after:absolute md:hover:after:block xl:gap-12">
                        {navLinks?.map((item) => {
                            const isActive = pathname?.indexOf(item.href) === 0;
                            const accordionSelected = accordionSelectedKey === item.key;
                            const selectionClassName = `before:bg-primary before:absolute before:bottom-0 before:left-0 md:before:left-4 md:before:h-1 md:before:right-4 ${
                                accordionSelected
                                    ? 'sticky top-0 bg-white before:top-0 before:w-1 md:before:top-auto md:before:w-auto md:before:hidden'
                                    : 'before:hidden'
                            } md:group-hover:before:z-20  md:group-hover:before:block${
                                isActive ? ' md:before:block' : ''
                            }`;

                            return (
                                <li key={item.key} className={`${mainSectionLiClassName} group`}>
                                    <div
                                        onClick={() => setAccordionSelectedKey(accordionSelected ? null : item.key)}
                                        className={`relative cursor-pointer p-4 after:absolute after:right-0 after:content-[attr(data-after)] md:px-4 md:py-3 ${selectionClassName}`}>
                                        {item.label}
                                        {item.subLinks?.length && (
                                            <HiChevronDown
                                                aria-hidden
                                                className={`absolute right-4 top-4 mt-1 text-2xl transition duration-200 ease-out md:hidden ${
                                                    accordionSelected ? 'rotate-180' : ''
                                                }`}
                                            />
                                        )}
                                    </div>
                                    {renderSubMenu(item)}
                                </li>
                            );
                        })}
                        <li className={`${mainSectionLiClassName} flex-1 md:text-right`}>
                            <MenuLink
                                className={`block p-4 md:inline-block md:py-3 ${mainSectionSelectionClassName(
                                    appointmentUrl(),
                                )}`}
                                href={appointmentUrl()}>
                                <div className={`relative ${tests.length ? 'md:pr-10' : ''}`}>
                                    <HiOutlineCalendar className="mr-1 inline-block align-text-bottom text-xl" />{' '}
                                    Programează-te<span className="hidden lg:inline"> online</span>
                                    {!!tests.length && (
                                        <div className="absolute right-0 top-0 z-0 ml-2 mt-1 md:mt-[2px]">
                                            {ping && (
                                                <div className="bg-secondary absolute -z-10 h-full w-full animate-ping rounded-full" />
                                            )}
                                            <div className="bg-secondary text-primary rounded-full px-3 text-sm">
                                                {tests.length}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </MenuLink>
                        </li>
                        <li className={`${mainSectionLiClassName} ${mainSectionSelectionClassName(contactUrl())}`}>
                            <MenuLink href={contactUrl()} className="block p-4 md:inline-block md:py-3">
                                <HiPhone title="Contact" className="mr-1 inline-block align-text-bottom text-xl" />{' '}
                                Contact
                            </MenuLink>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    );
}
