import Link from 'next/link';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';

import { UserRoleType } from 'src/types/users';
import useGlobalState from 'src/hooks/useGlobalState';

export const SwitchRoleMenuItems = ({ closeAllMenus }) => {
    const { user, switchRole } = useGlobalState();

    const last = user?.settings?.last_role;

    if (!last) {
        return null;
    }

    const roles = user?.roles?.filter((r) => ![UserRoleType.FORTAT, UserRoleType.EMAIL_MANAGER, last].includes(r));

    const titleForRole = (role: UserRoleType) => {
        switch (role) {
            case UserRoleType.PACIENT:
                return 'Pacient';
            case UserRoleType.DOCTOR:
                return 'Medic trimițător';
            case UserRoleType.LAB:
                return 'Clinică';
            case UserRoleType.ADMIN:
                return 'Administrator';
            case UserRoleType.EMAIL_MANAGER:
                return 'Manager emailuri';
            default:
                return role;
        }
    };

    return roles?.map((r: UserRoleType) => (
        <li className="border-dark-green/25 border-b" key={r}>
            <Link
                href=""
                onClick={() => {
                    switchRole(r);
                    closeAllMenus();
                }}
                className="hover:bg-light-green block p-4"
                aria-label={titleForRole(r)}>
                <HiOutlineSwitchHorizontal title={titleForRole(r)} className="mr-2 inline-block" />
                {titleForRole(r)}
            </Link>
        </li>
    ));
};
