import Link from 'next/link';
import { PiUserSwitch } from 'react-icons/pi';
import useGlobalState from 'src/hooks/useGlobalState';

export const ImpersonateMenuItem = ({ closeAllMenus }) => {
    const { user, stopImpersonate } = useGlobalState();

    if (!(user as any).impersonated) {
        return null;
    }

    return (
        <li className="border-t-third/25 bg-light-grey border-t">
            <Link
                href=""
                onClick={() => {
                    stopImpersonate();
                    closeAllMenus();
                }}
                className="hover:bg-third/10 text-third block p-4"
                aria-label="Impersonificare">
                <PiUserSwitch title="Ieși din mod impersonat" className="mr-2 inline-block" />
                Ieși din mod impersonat
            </Link>
        </li>
    );
};
