'use client';

import Link from 'next/link';
import { BiExit } from 'react-icons/bi';
import { HiOutlineCog, HiX } from 'react-icons/hi';

import { ImpersonateMenuItem } from './ImpersonateMenuItem';
import { SwitchRoleMenuItems } from './SwitchRoleMenuItems';
import { RiUser3Fill } from 'react-icons/ri';
import { myAccountUrl } from 'src/services/route-utils';
import { UserType } from 'src/hooks/useGlobalState';

type Props = {
    user: UserType;
    closeAccount: () => void;
    closeAllMenus: () => void;
};

export default function UserMenu({ user, closeAccount, closeAllMenus }: Props) {
    const MenuLink = (props) => <Link onClick={() => closeAllMenus()} {...props} />;

    return (
        <nav className="top-nav-sm-height h-screen-nav-sm-dynamic fixed left-0 right-0 z-40 min-w-[200px] bg-white font-normal drop-shadow-lg md:absolute md:bottom-auto md:left-auto md:right-4 md:top-auto md:mt-4 md:h-auto md:text-base">
            <div className="relative py-8 md:hidden">
                <HiX className="absolute right-4 top-4 cursor-pointer" onClick={closeAccount} title="Închide" />
            </div>
            <ul>
                <li className="border-dark-green/25 border-b">
                    <MenuLink
                        href={myAccountUrl('/')}
                        className="hover:bg-light-green block p-4"
                        aria-label="Contul meu">
                        <RiUser3Fill title={user.username} className="mr-2 inline-block" />
                        Contul meu
                    </MenuLink>
                </li>
                <SwitchRoleMenuItems closeAllMenus={closeAllMenus} />
                <li className="border-dark-green/25 border-b">
                    <MenuLink
                        href={myAccountUrl('/setari')}
                        className="hover:bg-light-green block p-4"
                        aria-label="Setări">
                        <HiOutlineCog title="Setări" className="mr-2 inline-block" />
                        Setări
                    </MenuLink>
                </li>
                <li className="border-dark-green/25 border-b md:border-0">
                    <MenuLink
                        href={myAccountUrl('/logout')}
                        className="hover:bg-light-green block p-4"
                        aria-label="Ieși din cont">
                        <BiExit title="Logout" className="mr-2 inline-block" />
                        Ieși din cont
                    </MenuLink>
                </li>
                <ImpersonateMenuItem closeAllMenus={closeAllMenus} />
            </ul>
        </nav>
    );
}
