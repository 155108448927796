'use client';

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { fetchJson, fetchJsonWithQuery } from 'lib/fetch-json';
import { useRouter } from 'next/navigation';
import { myAccountUrl } from 'src/services/route-utils';
import { UserRoleType } from 'src/types/users';
import toast from 'src/components/TailwindToaster';

export type UserType = {
    id: number;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    date_created: string;
    activated: number;
    email_sent: number;
    secret: string;
    email_disabled: number;
    account_disabled: number;
    developer_enrolled: number;
    roles: UserRoleType[];
    settings?: {
        results_notifications?: boolean;
        active_columns: string[];
        last_role?: UserRoleType;
    };
};

type GlobalStateType = {
    user: UserType | null;
    mutateUser: KeyedMutator<UserType | null>;
    setLoadUser: () => Promise<UserType | null>;
    logout: () => void;
    switchRole: (role: UserRoleType) => void;
    impersonate: (username: string) => void;
    stopImpersonate: () => void;
};

const GlobalStateContext = createContext<GlobalStateType>({
    user: null,
    mutateUser: async () => undefined,
    setLoadUser: async () => null,
    logout: () => {},
    switchRole: () => {},
    impersonate: () => {},
    stopImpersonate: () => {},
});

export function GlobalStateProvider(props: React.PropsWithChildren<{ initialUser?: UserType }>) {
    const { initialUser, ...rest } = props;
    const [user, setUser] = useState(initialUser || null);
    const {
        data: dataUser,
        mutate: mutateUser,
        isLoading,
    } = useSWR([`/json/user`], fetchJsonWithQuery, {
        revalidateOnFocus: true,
        revalidateIfStale: true,
        revalidateOnReconnect: true,
    });

    const router = useRouter();

    useEffect(() => {
        if (!isLoading) {
            setUser(dataUser);
        }
    }, [dataUser, isLoading]);

    const value = useMemo<GlobalStateType>(
        () => ({
            user,
            mutateUser,
            setLoadUser: () => mutateUser(),
            logout: () => {
                fetchJson('/json/logout', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }).finally(async () => {
                    await mutateUser(null);
                    router.replace(myAccountUrl());
                });
            },
            switchRole: (role: UserRoleType) =>
                fetchJson('/json/settings/switch-role', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ role }),
                }).then(() => mutateUser()),
            stopImpersonate: () =>
                fetchJson(`/json/users/${user?.username}/impersonate`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(async () => {
                        window.location.href = myAccountUrl();
                    })
                    .catch(() => {
                        toast.error('Eroare la întreruperea impersonării');
                    }),
            impersonate: (username: string) =>
                fetchJson(`/json/users/${username}/impersonate`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(async () => {
                        window.location.href = myAccountUrl();
                    })
                    .catch(() => {
                        toast.error('Eroare la încercarea impersonării');
                    }),
        }),
        [user, mutateUser, router],
    );

    return <GlobalStateContext.Provider value={value} {...rest} />;
}

// a hook which we are going to use whenever we need data from `GlobalStateProvider`
export default function useGlobalState() {
    const context = useContext(GlobalStateContext);

    if (!context) {
        throw new Error('You need to wrap GlobalStateProvider.');
    }

    return context;
}
