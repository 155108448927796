export enum UserRoleType {
    ADMIN = 'admin',
    DOCTOR = 'doctor',
    EMAIL_MANAGER = 'email-manager',
    FORTAT = 'fortat',
    LAB = 'lab',
    PACIENT = 'pacient',
}

export type UserFirstLastName = {
    first_name: string;
    last_name: string;
};
